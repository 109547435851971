import React from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const ActionButtonDropdown = ({ survey, handleEdit, handleDelete, handlePreview, handleChangeStatus, handleResponse }) => {

    return (
        <DropdownButton
            id="dropdown-basic-button"
            title="Actions"
            variant="secondary"
            className="dropdown-action-button"
        >
            <Dropdown.Item onClick={() => handleEdit(survey.id)}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDelete(survey.id)}>Delete</Dropdown.Item>
            <Dropdown.Item onClick={() => handlePreview(survey.slug)}>Live Preview</Dropdown.Item>
            <Dropdown.Item onClick={() => handleChangeStatus(survey.id, survey.status)}>Change Status</Dropdown.Item>
        </DropdownButton>
    );
};

export default ActionButtonDropdown;
