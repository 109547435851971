import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import axiosApi from '../api/axiosApi';

const StatusDialog = ({ id, isOpen, onRequestClose, onStatusChange, initialStatus }) => {
    const [status, setStatus] = useState(initialStatus);
    const [isLoading, setIsLoading] = useState(false);

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            await axiosApi.post('/api/survey/updateStatus',
                { survey_id: id, status },
                { headers: { 'Authorization': `Bearer ${localStorage.getItem('tk')}` } }
            );
            onStatusChange(true, status);
            onRequestClose();
        } catch (error) {
            console.error('Error updating status:', error);
            onStatusChange(false, null);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Change Status"
            className="fixed inset-0 flex items-center justify-center p-4"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
            <div className="bg-white rounded-xl shadow-lg p-6 w-full max-w-md relative" >
                <button
                    onClick={onRequestClose}
                    className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                >
                    <FaTimes size={24} />
                </button>
                <h2 className="text-xl font-bold mb-4 font-poppins">Change Status</h2>
                <div className="mb-4">
                    <label htmlFor="status" className="block text-gray-700 font-poppins">Status</label>
                    <select
                        id="status"
                        value={status}
                        onChange={handleStatusChange}
                        className="mt-2 block w-full p-2 border border-gray-300 rounded-lg"
                    >
                        <option value="draft">Draft</option>
                        <option value="active">Active</option>
                        <option value="completed">Completed</option>
                    </select>
                </div>
                <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className={`w-full ${isLoading ? 'bg-blue-300' : 'bg-purple-500'} text-white p-2 rounded-xl font-poppins`}
                >
                    {isLoading ? 'Processing...' : 'Select'}
                </button>
            </div>
        </Modal>
    );
};

export default StatusDialog;
