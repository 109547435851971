import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../api/AuthContext';

const PaymentComponent = ({ plan }) => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const isSubscribed = user.data.user.is_subscribed === 1;
    const isCurrentPlan = user.data.user.current_package === plan.package_id;
    const isAvailablePlan = plan.title === "Connect & Engage" || plan.title === "Amplify";

    const handlePayment = () => {
        if (isAvailablePlan) {
            navigate('/payment', { state: { amount: plan.amount, plan } });
        }
    };

    let buttonText = 'Coming Soon';
    let buttonClass = 'bg-gray-400 cursor-not-allowed';

    if (isSubscribed && isCurrentPlan) {
        buttonText = 'Current Plan';
        buttonClass = 'bg-green-500 cursor-default';
    } else if (isAvailablePlan) {
        buttonText = 'Subscribe Now';
        buttonClass = 'bg-purple-500 hover:bg-purple-600';
    }

    return (
        <button
            onClick={handlePayment}
            className={`w-full py-2 px-4 rounded font-medium text-white transition duration-300 ${buttonClass}`}
            disabled={!isAvailablePlan || (isSubscribed && isCurrentPlan)}
        >
            {buttonText}
        </button>
    );
};

export default PaymentComponent;
