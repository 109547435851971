import axiosApi from "../api/axiosApi";



function isValidMessage(message) {
    return typeof message === 'string' && message.trim() !== '';
}

export async function sendMsgToOpenAI(systemPrompt, message, chatId) {
    console.log("Sending message to OpenAI:", message);

    if (!isValidMessage(message)) {
        console.error("Invalid message format:", message);
        return "Sorry, the message format is incorrect.";
    }

    try {
        const response = await axiosApi.post('/api/chatCompletion', {
            "prompt": message,
            "system_prompt": systemPrompt,
            "chatId": chatId
        });


        if (response.data.data.response) {
            return response.data.data.response;
        } else {
            console.error("Unexpected response structure:", response);
            return "Sorry, I couldn't generate a response at the moment.";
        }
    } catch (error) {
        console.error("Error communicating with OpenAI:", error);
        return "Sorry, there was an error processing your request.";
    }
}
