import React from 'react';

const ReachOutModal = ({ isOpen, onClose, message, link }) => {
    if (!isOpen) return null; // Don't render if not open

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-xl shadow-lg p-6 w-11/12 sm:w-96 relative">
                <button onClick={onClose} className="absolute top-0 right-0 m-4 text-black">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <h2 className="text-xl font-semibold mb-4">Reach Out</h2>
                <p className="mb-4">{message}</p>
                {link && <a href={link} target="_blank" rel="noopener noreferrer" className="mb-4 inline-block bg-blue-500 text-white px-4 py-2 rounded-xl hover:bg-blue-600">Schedule a Call</a>}
            </div>
        </div>
    );
};

export default ReachOutModal;
