import React, { useEffect, useState } from 'react';
import { FaTimes, FaCheck } from 'react-icons/fa';
import axios from 'axios';
import PaymentComponent from './PaymentComponent';
import { ClipLoader } from 'react-spinners';
import { useAuth } from '../api/AuthContext';
import { useLocation } from 'react-router-dom';

const SubscribeModal = ({ onClose }) => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const location = useLocation()

  const fetchPackages = async () => {
    try {
      const response = await axios.get(`https://onetapaisolutions.com/relevantreach/backend/public/api/packages`);
      return response.data;
    } catch (error) {
      console.error('Error fetching packages:', error);
      throw error;
    }
  };

  useEffect(() => {
    const loadPackages = async () => {
      try {
        const data = await fetchPackages();
        setPlans(data);
      } catch (error) {
        console.log('Error fetching packages:', error);
        setError('Error fetching packages');
      } finally {
        setLoading(false);
      }
    };

    loadPackages();
  }, []);

  if (loading) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
        <ClipLoader color="#2130B7" loading={loading} size={50} />
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-70 flex justify-center items-center z-50 p-4">
      <div className="bg-white rounded-2xl shadow-2xl p-6 md:p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto relative">
        <button
          onClick={() => onClose(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors duration-200"
          aria-label="Close modal"
        >
          <FaTimes size={24} />
        </button>
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-2">Choose Your Perfect Plan</h2>
          <p className="text-gray-600">Unlock the full potential of our services</p>
        </div>
        {error && (
          <div className="text-red-500 text-center mb-6 p-3 bg-red-100 rounded-lg">
            {error}
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {plans.map((plan, index) => (
            <div
              className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transform hover:scale-105 transition duration-300 border border-gray-200"
              key={index}
            >
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-gray-900">{plan.title}</h3>
                <p className="text-gray-600 mt-1">{plan.description}</p>
              </div>
              <div className="text-orange-500 text-4xl font-bold flex items-center justify-center mb-4">
                <span className="text-2xl mr-1">$</span>
                <span>{plan.amount}</span>
                <span className="text-xl font-medium text-gray-400 ml-1">/mo</span>
              </div>
              <ul className="text-gray-700 space-y-3 mb-6">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <div className="mt-6">
                <PaymentComponent plan={plan} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubscribeModal;
