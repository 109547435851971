import { useState, useEffect } from "react";
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { ArrowUpDown, ChevronDown } from "lucide-react";
import axios from "axios";

import { Button } from "../components1/ui/button.jsx";
import { Input } from "../components1/ui/input.jsx";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "../components1/ui/dropdown-menu.jsx";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../components1/ui/table.jsx";
import { ClipLoader } from "react-spinners";
import AIbot from "./AIbot.js";

// function to format date
const formatDate = (date) => {
    const options = {
        year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
}

const columns = [

    {
        accessorKey: "transaction_id",
        header: ({ column }) => (
            <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
                <p className="font-bold text-black font-poppins">Transaction ID</p>
                <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
        ),
        cell: ({ row }) => (
            <div className="ml-5 font-poppins">{row.getValue("transaction_id")}</div>
        ),
    },
    {
        accessorKey: "plan_name",
        header: ({ column }) => (
            <Button
                variant="ghost"

            >
                <p className="font-bold text-black font-poppins">Plan</p>
                <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>),
        cell: ({ row }) => {
            const planName = row.getValue("plan_name");
            let bgColor = "bg-gray-500 text-white"; // Default background color

            if (planName === "Basic") {
                bgColor = "bg-green-500";
            } else if (planName === "Standard") {
                bgColor = "bg-blue-500";
            } else if (planName === "Premium") {
                bgColor = "bg-red-500 text-white";
            }
            return (
                <div
                    className={`px-2 py-1 font-bold text-black rounded-lg text-center ${bgColor}`}
                >
                    {planName}
                </div>
            );
        },
    },
    {
        accessorKey: "date_of_payment",
        header: ({ column }) => (
            <Button
                variant="dark"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
                <p className="font-bold text-black font-poppins">Date of Payment</p>
                <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
        ),
        cell: ({ row }) => (
            <div className="lowercase">{formatDate(row.getValue("date_of_payment"))}</div>
        ),
    },
    {
        accessorKey: "status",
        header: ({ column }) => (
            <Button
                variant="ghost"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
                <p className="font-bold text-black font-poppins">Status</p>
                <ArrowUpDown className="ml-2 h-4 w-4 " />
            </Button>
        ),
        cell: ({ row }) => {
            const status = row.getValue("status");
            return (
                <div
                    className={`ml-5 py-1 rounded-lg text-center ${status === "succeeded" ? "bg-green-400" : "bg-red-500 text-white"
                        }`}
                >
                    {status}
                </div>
            );
        },
    },
    {
        accessorKey: "payment_method",
        header: ({ column }) => (
            <Button
                variant="ghost font-poppins"
                onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
                <p className="font-bold text-black font-poppins">Payment Method</p>
                <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
        ),
        cell: ({ row }) => (
            <div className="ml-5 font-poppins">{row.getValue("payment_method")}</div>
        ),
    },
    // {
    //     accessorKey: "invoice_url",
    //     header: "Invoice",
    //     cell: ({ row }) => (
    //         <Button
    //             variant="link"
    //             onClick={() => window.open(row.getValue("invoice_url"), "_blank")}
    //         >
    //             Download Invoice
    //         </Button>
    //     ),
    // },
];

export function SubscriptionTable() {
    const [sorting, setSorting] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [columnVisibility, setColumnVisibility] = useState({});
    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(
                    `https://onetapaisolutions.com/relevantreach/backend/public/api/user/transactions`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("tk")}`,
                        },
                    }
                );
                setData(response.data.data);
            } catch (error) {
                setError("Failed to fetch transactions");
            } finally {
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <ClipLoader color="#2130B7" loading={loading} size={50} />
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }










    return (



        <div className="flex h-screen bg-gray-100" >

            <div className="flex-1 flex flex-col">

                <main className="flex-1 overflow-y-auto p-6">
                    <div className=' mb-10 rounded-xl'>



                        <div className="flex items-center py-4 ml-4 font-poppins">
                            <Input
                                placeholder="Filter Transactions By ID"

                                value={(table.getColumn("transaction_id")?.getFilterValue()) ?? ""}
                                onChange={(event) =>
                                    table.getColumn("transaction_id")?.setFilterValue(event.target.value)
                                }
                                className="max-w-sm font-poppins"
                            />
                            {/* <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="outline" className="ml-auto font-poppins">
                                        Columns <ChevronDown className="ml-2 h-4 w-4" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end" className='font-poppins'>
                                    {table
                                        .getAllColumns()
                                        .filter((column) => column.getCanHide())
                                        .map((column) => {
                                            return (
                                                <DropdownMenuCheckboxItem
                                                    key={column.id}
                                                    className="capitalize font-poppins"
                                                    checked={column.getIsVisible()}
                                                    onCheckedChange={(value) =>
                                                        column.toggleVisibility(!!value)
                                                    }
                                                >
                                                    {column.id}
                                                </DropdownMenuCheckboxItem>
                                            );
                                        })}
                                </DropdownMenuContent>
                            </DropdownMenu> */}
                        </div>
                        <div className="rounded-md border font-poppins">
                            <Table>
                                <TableHeader>
                                    {table.getHeaderGroups().map((headerGroup) => (
                                        <TableRow className='font-poppins' key={headerGroup.id}>
                                            {headerGroup.headers.map((header) => {
                                                return (
                                                    <TableHead className='font-poppins' key={header.id}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                    </TableHead>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {table.getRowModel().rows?.length ? (
                                        table.getRowModel().rows.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                data-state={row.getIsSelected() && "selected"}
                                                className='font-poppins'
                                            >
                                                {row.getVisibleCells().map((cell) => (
                                                    <TableCell className='font-poppins' key={cell.id}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={columns.length}
                                                className="h-24 text-center font-poppins"
                                            >
                                                No results.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <div className="flex items-center justify-end space-x-2 py-4">
                            <div className="flex-1 text-sm text-muted-foreground ml-2 font-poppins">
                                {table.getFilteredSelectedRowModel().rows.length} of{" "}
                                {table.getFilteredRowModel().rows.length} row(s) selected.
                            </div>
                            <div className="space-x-2 font-poppins">
                                <Button
                                    variant="outline"
                                    size="sm"
                                    className='font-poppins'
                                    onClick={() => table.previousPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    Previous
                                </Button>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    className='font-poppins'
                                    onClick={() => table.nextPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>

                    </div>

                </main>
                <AIbot />
            </div>
        </div >
    );
}
