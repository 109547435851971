import React, { Suspense, lazy, useEffect, useMemo, useState } from 'react';
import { createBrowserRouter, Outlet, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './api/AuthContext';
import ProtectedRoute from './api/ProtectedRoute';
import Spinner from './Components/Spinner';
import './App.css';
import { SubscriptionTable } from './Components/SubscriptionTable';
import SubscribeModel from './Components/SubcribeModel';
import { AdminTable } from './Components/AdminTable';
import { SurveyProvider } from './Components/ProjectTables';
import TermsOfService from './Components/TermsOfService';
const Login = lazy(() => import('./Components/Login'));
const Register = lazy(() => import('./Components/Register'));
const ForgotPassword = lazy(() => import('./Components/ForgotPassword'));
const Dashboard = lazy(() => import('./Components/Dashboard'));
const Invoice = lazy(() => import('./Components/Invoice'));
const Subscribe = lazy(() => import('./Components/Subscribe'));
const ChangePassword = lazy(() => import('./Components/ChangePassword'));
const ProfilePage = lazy(() => import('./Components/ProfilePage'));
const SurveyDashboard = lazy(() => import('./Components/Graph/SurveyDashboard'));
const PaymentGateway = lazy(() => import('./Components/PaymentGateway'));
const PrivacyPolicy = lazy(() => import('./Components/Privacy'));
const NotFound = lazy(() => import('./Components/NotFound'));
const ProjectTables = lazy(() => import('./Components/ProjectTables'));
const SurveyEditor = lazy(() => import('./Components/SurveyEditor'));
const Preview = lazy(() => import('./Components/Preview'));
const HeaderSidebar = lazy(() => import('./Components/HeaderSidebar'));
const PaymentSuccess = lazy(() => import('./Components/PaymentSuccess'));
const PaymentFailed = lazy(() => import('./Components/PaymentFailed'));

export const App = () => {
  const location = useLocation();
  const { user, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(true)
  const [isMobile, setIsMobile] = useState(false);

  const showHeaderSidebar = useMemo(() => {
    const protectedPaths = ['/dashboard', '/transactions', '/subscribe', '/user/profile', '/user/table'];
    return user && protectedPaths.some(path => location.pathname === (path));
  }, [location.pathname, user]);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change this value based on your breakpoint
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // Check the screen size on component mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // Effect to wait for the user data before showing the content
  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading]);

  if (isLoading) {

    return <Spinner />;
  }

  return (
    <div className="flex flex-col h-screen">
      {showHeaderSidebar && (
        <Suspense fallback={<Spinner />}>
          <HeaderSidebar />
        </Suspense>
      )}
      {!showHeaderSidebar && (
        <div className="flex-1 overflow-y-auto bg-gray-50 w-full">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </div>
      )}
    </div>
  );
};

// Router Configuration
export const AppRouter = createBrowserRouter([
  {
    path: '/',
    element: (

      <AuthProvider>
        <SurveyProvider>
          <App />
        </SurveyProvider>
      </AuthProvider >

    ),
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        )
      },
      {
        path: 'invoice',
        element: (
          <ProtectedRoute>
            <Invoice />
          </ProtectedRoute>
        )
      },
      {
        path: 'subscribe',
        element: (
          <ProtectedRoute>
            <Subscribe />
          </ProtectedRoute>
        )
      },
      {
        path: 'user/profile',
        element: <ProfilePage />
      },
      {
        path: 'user/changepassword',
        element: <ChangePassword />
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />
      },
      {
        path: 'terms',
        element: <TermsOfService />
      },
      {
        path: 'transactions',
        element: (
          <ProtectedRoute>
            <SubscriptionTable />
          </ProtectedRoute>
        )
      },
      {
        path: 'payment',
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Spinner />}>
              <PaymentGateway />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: 'survey/my-survey',
        element: (
          <ProtectedRoute>
            <ProjectTables fromHome={false} />
          </ProtectedRoute>
        )
      },
      {
        path: 'create-survey',
        element: (
          <ProtectedRoute>
            <SurveyEditor />
          </ProtectedRoute>
        )
      },
      {
        path: 'survey/my-survey/edit-survey/:id',
        element: (
          <ProtectedRoute>
            <SurveyEditor isEditMode={true} />
          </ProtectedRoute>
        )
      },
      {
        path: 'dashboard/edit-survey/:id',
        element: (
          <ProtectedRoute>
            <SurveyEditor isEditMode={true} />
          </ProtectedRoute>
        )
      },
      {
        path: 'survey/:slug',
        element: <Preview />
      },
      {
        path: 'survey/my-survey/previewSurvey/:slug',
        element: (
          <ProtectedRoute>
            <Preview />
          </ProtectedRoute>
        )
      },
      {
        path: 'dashboard/previewSurvey/:slug',
        element: (
          <ProtectedRoute>
            <Preview />
          </ProtectedRoute>
        )
      },
      {
        path: 'dashboard/survey-dashboard/:slug',
        element: (
          <ProtectedRoute>
            <SurveyDashboard />
          </ProtectedRoute>
        )
      },
      {
        path: 'survey/my-survey/survey-dashboard/:slug',
        element: (
          <ProtectedRoute>
            <SurveyDashboard />
          </ProtectedRoute>
        )
      },
      {
        path: 'payment-success',
        element: (
          <Suspense fallback={<Spinner />}>
            <PaymentSuccess />
          </Suspense>
        )
      },
      {
        path: 'payment-failed',
        element: (
          <Suspense fallback={<Spinner />}>
            <PaymentFailed />
          </Suspense>
        )
      },
      {
        path: '*',
        element: <NotFound />
      },
      {
        path: '/subcribemodel',
        element: <SubscribeModel />
      },
      {
        path: 'user/table',
        element: <AdminTable />
      },

    ]
  }
]);

export default AppRouter;
