import React from 'react';
import { ClipLoader } from 'react-spinners';

const Spinner = () => {
    return (
        <div className="flex items-center justify-center min-h-screen">
            <ClipLoader color="#2130B7" loading={true} size={60} />
        </div>
    );
};

export default Spinner;