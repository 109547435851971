import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, Table } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import StatusDialog from './StatusDialog';
import { useAuth } from '../api/AuthContext';
import ActionButtonDropdown from './ActionButtonDropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ClipLoader } from 'react-spinners';
import AIbot from './AIbot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axiosApi from '../api/axiosApi';
import { createContext } from 'react';

const defaultImage = 'https://via.placeholder.com/150';

const ProjectTables = ({ fromHome = false }) => {
  const { surveyData, setSurveyData, lastFetchTime, setLastFetchTime } = useContext(SurveyContext);
  const [loading, setLoading] = useState(!surveyData);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchSurveys = useCallback(async () => {
    const now = Date.now();
    if (surveyData && lastFetchTime && now - lastFetchTime < 300000) { // 5 minutes cache
      setLoading(false);
      return;
    }

    try {
      const response = await axiosApi.get(`/api/survey/all/${user.data.user.id}`);
      const newSurveyData = response.data.data.map(survey => ({
        ...survey,
        responseCount: response.data.responseCount.find(rc => rc.survey_id === survey.id)?.survey_count || 0
      }));
      setSurveyData(newSurveyData);
      setLastFetchTime(now);
    } catch (error) {
      console.error('Error fetching survey data:', error);
    } finally {
      setLoading(false);
    }
  }, [user.data.user.id, setSurveyData, setLastFetchTime, surveyData, lastFetchTime]);

  useEffect(() => {
    fetchSurveys();
  }, [fetchSurveys]);

  const handleEdit = useCallback((id) => navigate(`edit-survey/${id}`, { replace: false }), [navigate]);
  const handlePreview = useCallback((id) => navigate(`previewSurvey/${id}`, { replace: false }), [navigate]);
  const handleResponse = useCallback((slug) => navigate(`survey-dashboard/${slug}`, { replace: false }), [navigate]);

  const handleDelete = useCallback(async (id) => {
    try {
      await axiosApi.post('/api/survey/delete', {
        user_id: user.data.user.id,
        survey_id: id,
      });
      setSurveyData(prevSurveys => prevSurveys.filter(survey => survey.id !== id));
    } catch (error) {
      console.error('Error deleting survey:', error);
    }
  }, [user.data.user.id, setSurveyData]);

  const handleChangeStatus = useCallback((id, currentStatus) => {
    setSelectedSurvey({ id, currentStatus });
    setIsDialogOpen(true);
  }, []);

  const handleStatusChange = useCallback((success, newStatus, surveyId) => {
    if (success) {
      setSurveyData(prevSurveys => prevSurveys.map(survey =>
        survey.id === surveyId ? { ...survey, status: newStatus } : survey
      ));
    }
  }, []);

  const handleCreateSurvey = useCallback(() => navigate('/create-survey', { replace: true }), [navigate]);

  const formatDate = useMemo(() => (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  }, []);

  const renderSurveyRow = useCallback((survey) => (
    <tr key={survey.id} className="border-t">
      <td className="px-2 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10">
            <img
              className="h-10 w-10 rounded-full"
              src={survey.logo || defaultImage}
              alt="survey logo"
              loading="lazy"
            />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900 font-poppins">{survey.title}</div>
            <div className="text-sm text-gray-500 font-poppins">{survey.description}</div>
          </div>
        </div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <Link to={`/survey/${survey.slug}`} target="_blank" className="text-sm text-blue-500">View</Link>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full font-poppins">
          {survey.responseCount}
        </span>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${survey.status === "pending" ? "bg-red-100 text-red-800" :
          survey.status === "halt" ? "bg-yellow-100 text-yellow-800" :
            "bg-green-100 text-green-800"
          }`}>
          {survey.status}
        </span>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <Link to={`survey-dashboard/${survey.slug}`} target="_blank" className="text-sm text-blue-500">
          View Analytics
        </Link>
      </td>
      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-poppins">
        {formatDate(survey.created_at)}
      </td>
      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-poppins">
        <ActionButtonDropdown
          survey={survey}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handlePreview={handlePreview}
          handleChangeStatus={() => handleChangeStatus(survey.id, survey.status)}
          handleResponse={handleResponse}
        />
      </td>
    </tr>
  ), [formatDate, handleChangeStatus, handleDelete, handleEdit, handlePreview, handleResponse]);

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <ClipLoader color='#2130B7' size={50} />
      </div>
    );
  }

  return (
    <div className={fromHome ? '' : 'bg-gradient-to-r from-[#3E3DAA] to-[#A86B7F]'}>
      <div className="w-full md:px-0 px-4 sm:px-6 lg:px-8">
        {/* Header and Create Survey button */}
        <div className='flex justify-between items-center mb-6 px-4 py-2'>
          <div className='flex items-center'>
            {!fromHome && (
              <button onClick={() => navigate(-1)} className="mr-3">
                <FontAwesomeIcon icon={faArrowLeft} className="text-white text-lg" />
              </button>
            )}
            <img onClick={() => navigate(-1)} src="/lightlogo.png" alt="Logo" className="h-8 w-auto cursor-pointer" />
          </div>
          <button
            className="px-2 py-2 sm:px-3 sm:py-1.5 md:px-4 md:py-2 lg:px-5 lg:py-2.5 font-poppins text-sm sm:text-xs md:text-sm lg:text-base rounded-full bg-purple-600 text-white font-semibold focus:outline-none hover:bg-purple-700"
            onClick={handleCreateSurvey}
          >
            Create Survey
          </button>
        </div>

        {/* Survey Table */}
        <Card>
          <CardBody>
            <CardTitle tag="h5" className="font-bold text-lg ml-3 mt-2">Survey Listing</CardTitle>
            <CardSubtitle className="mb-2 text-gray-200 text-sm ml-3 mt-2 font-poppins" tag="h6">
              Overview of the survey
            </CardSubtitle>
            <div className="overflow-x-auto">
              <Table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr className="bg-gray-50 font-poppins">
                    {['Survey Details', 'Survey Link', 'Response Count', 'Status', 'Analytics', 'Created on', 'Action'].map((header) => (
                      <th key={header} className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 font-poppins">
                  {surveyData && surveyData.map(renderSurveyRow)}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        {/* Create Survey button for empty state */}
        {surveyData.length === 0 && (
          <button
            className="px-4 py-2 font-poppins m-4 ml-2 rounded-xl text-white bg-[#AD2FFB]"
            onClick={handleCreateSurvey}
          >
            Create Survey
          </button>
        )}

        {/* Status Dialog */}
        {selectedSurvey && (
          <StatusDialog
            id={selectedSurvey.id}
            isOpen={isDialogOpen}
            onRequestClose={() => setIsDialogOpen(false)}
            onStatusChange={(success, newStatus) => handleStatusChange(success, newStatus, selectedSurvey.id)}
            initialStatus={selectedSurvey.currentStatus}
          />
        )}

        <AIbot />
      </div>
    </div>
  );
};

export default React.memo(ProjectTables);

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
  const [surveyData, setSurveyData] = useState(null);
  const [lastFetchTime, setLastFetchTime] = useState(null);

  const value = useMemo(() => ({
    surveyData,
    setSurveyData,
    lastFetchTime,
    setLastFetchTime
  }), [surveyData, lastFetchTime]);

  return (
    <SurveyContext.Provider value={value}>
      {children}
    </SurveyContext.Provider>
  );
};









