import React from 'react';

const TermsOfService = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
            <div className="w-full max-w-4xl bg-white rounded-lg shadow-md p-8">
                <h1 className="text-3xl font-semibold mb-6 text-center">Terms and Conditions</h1>
                <p className="text-center mb-4 text-gray-600">Effective Date: 09/09/24</p>

                <div className="overflow-x-auto">
                    <p className="mb-4">
                        By signing up and logging in to the Relevant Reach website, and by using the functions offered by the site, the person or company that does so agrees to be bound by these Terms and Conditions ("Terms"). Relevant Reach ("RR" or "we") is a service of Dowden Consulting LLC, an Indiana limited liability company.
                    </p>

                    <p className="mb-4">
                        You (the person entering the signup/login information) must provide your real name, and you represent and warrant that you are 18 or older. If you are signing up on behalf of an entity, (a) you must provide your organization's full legal name, (b) you represent and warrant that you are authorized to act on behalf of that entity, and (c) "you" refers to that entity throughout these terms and conditions except for this paragraph. We reserve the right to verify all signup/login information.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">1. Term</h2>
                    <p className="mb-4">
                        The term of our agreement begins on the date and time you accept these terms and conditions, and continues in effect until either party terminates (and until any disputes have been resolved after you terminate). Subject to our right pursuant to paragraph 8 below, we will not terminate when one of your surveys is active.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">2. Survey Contents</h2>
                    <p className="mb-4">
                        a. Generally. You're in complete control of what questions to ask and how to ask them. We do not review your surveys before you send them out. It's up to you to make sure your surveys are properly drafted and grammatically correct.
                    </p>
                    <p className="mb-4">
                        b. Topics. You are responsible for ensuring that your surveys are appropriate for your target audience. You must inform us any time a survey is intended only for adult audiences, and for each such survey you must provide an appropriately-flagged mailing list.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">3. Privacy</h2>
                    <p className="mb-4">
                        RR's privacy policy applies to the information we collect about you and anyone who contacts us on your behalf. You are responsible for complying with all state, federal, and foreign privacy laws that apply to your surveys as sent. Within the U.S., the Federal Trade Commission (FTC) requires that whatever your stated privacy policy is, you must comply with it. Numerous state privacy statutes such as the California Privacy Rights Act (CPRA) as well as foreign privacy laws such as the EU's General Data Protection Regulation (GDPR) contain detailed requirements for privacy policy content and notification, opting out, correction, deletion, and data sharing.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">4. Intellectual Property</h2>
                    <p className="mb-4">
                        a. Copyright. You own the copyright to all original content you create when putting together your surveys. You grant us a revocable, worldwide, royalty-free license to copy and distribute your surveys as you direct, during the term.
                    </p>
                    <p className="mb-4">
                        b. Trademark. This agreement does not involve trademark licensing. If either of us wishes to use the other's trademarks or brand names (for example for promo purposes), we must get written permission to do so.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">5. Confidential Information</h2>
                    <p className="mb-4">
                        This agreement does not entail the disclosure of trade secrets or of other proprietary or confidential information of one party, to the other.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">6. Your Representations and Warranties</h2>
                    <p className="mb-4">
                        You represent and warrant to us that (a) nothing in any of your surveys infringes or will infringe third-party intellectual property rights, and (b) whether or not you ask questions about race, ethnicity, national origin, religion, gender, or marital status, you will not use such surveys to unlawfully discriminate against any person.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">7. Our Warranties and Limitations Thereof</h2>
                    <p className="mb-4">
                        RR warrants that
                        a. It will not use the contents of your survey for any of RR's own commercial purposes, except as permitted by you.
                        b. it will not disclose the contents of your survey(s) to any third parties except as directed by you (unless required to do so by legal process).
                        c. it will provide the survey hosting and list administration services as described, using commercially-recognized servers, during the term. To the extent RR breaches this warranty, RR will refund your payment made to RR that covers the period of breach.
                    </p>
                    <p className="mb-4">
                        RR EXPRESSLY DISCLAIMS ANY OTHER REPRESENTATION OR WARRANTY, EXPRESS OR IMPLIED, OR IMPLIED WARRANTIES ARISING OUT OF USAGE OF TRADE, COURSE OF DEALING OR COURSE OF PERFORMANCE. IN NO CASE WILL RR BE LIABLE FOR INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES, EVEN IF RR HAS BEEN ADVISED REGARDING, OR SHOULD HAVE KNOWN, THE POSSIBILITY OF SUCH DAMAGES.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">8. Our Remedies</h2>
                    <p className="mb-4">
                        Should you breach these terms and conditions, our remedies include but are not limited to the following:
                        a. If it comes to our attention that something in your content infringes third party intellectual property rights, or that you use such content to unlawfully discriminate, we may block that content and/or remove it from our servers.
                        b. If it comes to our attention that something in your content is objectionable, we reserve the right to remove it pursuant to 47 U.S.C. § 230(c).
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">9. Indemnification</h2>
                    <p className="mb-4">
                        You will indemnify and hold us harmless (as indemnitee) from all third-party claims raised, settled, or adjudicated that arise from your breach of these terms and conditions, including reasonable attorney fees. You must promptly notify us if you become aware of a claim that may lead to an indemnification obligation. We will have sole control over the defense and settlement of any such claim, and you will cooperate with us in connection with such defense or settlement. You may also participate at your own expense in the defense of any such claim but may not acquiesce to any judgment or enter into any settlement that adversely affects our indemnitee's rights or interests without our prior written consent.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">10. Dispute Resolution</h2>
                    <p className="mb-4">
                        The parties will attempt to resolve any dispute arising out of these terms and conditions (other than an action to collect undisputed debt or for injunctive relief) in a spirit of cooperation without formal proceedings. A dispute which cannot be so resolved will, upon the written demand of either party, be subject to mandatory mediation, which, if not successful, will be followed by mandatory arbitration. Mediation will take place in Elkhart County, Indiana, pursuant to Rule 2 Rules for Alternative Dispute Resolution, Indiana Rules of Court. Each party will pay half the cost of the mediator. If the mediation is not successful, or if the parties agree to skip mediation, arbitration will proceed under Rule 3 of the same Rules for Alternative Dispute Resolution. The parties consent to mediation and/or arbitration by voice or video conference if the mediator/arbitrator agrees. The arbitrator may not award punitive damages or any other form of relief not contemplated in the Project Statement. Regarding each issue submitted to arbitration, the parties shall request the arbitrator to issue a short opinion of the basis upon which it was arrived at. Judgment upon the award, if any, rendered by the arbitrator may be entered in any court having jurisdiction thereof.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">11. Applicable Law, Jurisdiction and Venue</h2>
                    <p className="mb-4">
                        Indiana state law governs these terms and conditions. For purposes of enforcing any arbitration award or for injunctive relief, the parties will accept exclusive jurisdiction and venue in any court of competent jurisdiction where the defendant is located.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">12. Notices</h2>
                    <p className="mb-4">
                        Notices by either party to the other will be deemed effective when hand-delivered, or when sent via return-receipt delivery, or when transmitted by email if a copy of the sent message is retained, using the most recent contact information provided in writing by the party to whom such notice is sent. Either party may change its contact information pursuant to this provision.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">13. Restrictions on Assignment and Delegation</h2>
                    <p className="mb-4">
                        Neither party may assign any of its rights or delegate any of its duties hereunder without the consent of the other party, which will not unreasonably refuse such consent.
                    </p>

                    <h2 className="text-xl font-semibold mb-2 mt-6">14. Miscellaneous</h2>
                    <p className="mb-4">
                        These terms and conditions constitute the entire agreement of the parties with respect to your surveys conducted through RR, and supersedes all prior agreements. Any changes to these terms and conditions must be made in writing. If either party does not make full use of its rights under these terms and conditions, that does not constitute a waiver or a course of dealing. Any delays beyond the control of either party (force majeure) will suspend the parties' mutual obligations for the duration of the force majeure; the parties will cooperate to reschedule. Your relationship with RR is that of an independent contractor.
                    </p>

                    <p className="mt-6 text-center text-gray-600">
                        Last updated 09/09/24
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfService;
